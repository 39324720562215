export async function areAdsBlocked() {
    const elem = document.querySelector('.ad.ad-wrap');

    try {
        await import('https://ads.e24.no/fresk.js');

        if (window.fluepapir === true) {
            return false;
        }

        if (getComputedStyle(elem).display !== 'none') {
            return false;
        }
    } catch (err) {
        // Do nothing
    }

    return true;
}
