import type { ClientType } from './types';

export function isAppClient(clientType: ClientType) {
    return clientType.endsWith('-app');
}

export function isIosAppClient(clientType: ClientType) {
    if (!isAppClient(clientType)) {
        return false;
    }
    return clientType.startsWith('ios');
}

export function isWebClient(clientType: ClientType) {
    return clientType === 'web';
}
