import type { EventInputPage } from '@e24/mbbs';

export const enum Route {
    Front = 'FRONT',
}

const ROUTES: { [K in Route]: EventInputPage } = {
    FRONT: {
        id: 'front',
        type: 'Frontpage',
    },
} as const;

export const getRouteData = (key: Route) => ROUTES[key];
