import type { DeviceType } from '@innhold/core/device/types';

const getFinnBlinkAdsToReplace = (): Array<string[]> => {
    return [
        ['board_backfill_1', 'hayabusa_mobile_1'],
        ['board_backfill_2', 'hayabusa_mobile_2'],
        ['board_backfill_3', 'hayabusa_mobile_3'],
        ['board_backfill_4', 'hayabusa_mobile_4'],
        ['board_backfill_6', 'hayabusa_mobile_5'],
        ['netboard_backfill_1', 'hayabusa_desktop_1'],
        ['netboard_backfill_2', 'hayabusa_desktop_2'],
        ['netboard_backfill_3', 'hayabusa_desktop_3'],
        ['netboard_backfill_4', 'hayabusa_desktop_4'],
    ];
};

const getFinnBlinkSizes = (deviceType: DeviceType): { width: number; height: number } => {
    const isMobile = deviceType === 'mobile';
    if (isMobile) {
        return { width: 375, height: 250 };
    }
    return { width: 580, height: 450 };
};

export { getFinnBlinkAdsToReplace, getFinnBlinkSizes };
