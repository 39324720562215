import { DATA_CTA } from '@innhold/core/front/constants';
import { getPulse } from '@innhold/core/tracking/pulse';

export default async () => {
    const pulse = await getPulse();

    const ctas = document.querySelectorAll(`[${DATA_CTA}]`);

    ctas.forEach((cta) => {
        const topic = cta.getAttribute(DATA_CTA);

        cta.addEventListener('click', () => {
            pulse.trackElementClick({
                object: {
                    id: `cta-card-${topic}`,
                    type: 'Cta',
                    name: topic,
                },
            });
        });
    });
};
