import {
    getPartnerstudioSubtitle,
    getPartnerstudioTitle,
    getTagsFromUrl,
} from '@innhold/components/shared/ContentAds/utils/partnerstudio';
import { getDeviceTypeFromRoot } from '@innhold/core/device/client';
import { CONTENT_MARKETING_ITEM_CLASS_NAME } from '@innhold/core/front/constants';
import { hasConsentFor } from '@innhold/core/privacy';
import { getPulse } from '@innhold/core/tracking/pulse';

const device = getDeviceTypeFromRoot();

const sendThirdpartyTracking = (front: HTMLElement) => {
    const pixel = front?.querySelector<HTMLElement>('[data-tracking-pixel]');

    if (!pixel) {
        return;
    }

    new Image().src = pixel.dataset.trackingPixel;
};

const getPulseData = (item: HTMLElement) => {
    const { dataset: pulseData } = item;
    const {
        pulseVariantId: variant,
        pulseRevisionId: revision,
        drfrontId: drfrontId,
        clientName,
        creativeId,
        editionId,
        itemId,
        url,
        title,
        algorithm,
        contentPlacement: placement,
    } = pulseData;
    return {
        variant,
        revision,
        drfrontId,
        clientName,
        creativeId,
        editionId,
        itemId,
        url,
        title,
        algorithm,
        placement,
    };
};

export const track = async (target: HTMLElement) => {
    const item = target.querySelector<HTMLElement>(
        `.${CONTENT_MARKETING_ITEM_CLASS_NAME}`
    );

    if (!item) {
        return;
    }

    const {
        variant,
        revision,
        drfrontId,
        clientName,
        creativeId,
        editionId,
        itemId,
        url,
        placement,
        algorithm,
    } = getPulseData(item);

    const imageUrl =
        item?.querySelector<HTMLImageElement>('.teaser-main-image')?.src || 'NULL';
    const link = item?.querySelector('a')?.href ?? '';
    const tags = getTagsFromUrl(link);
    const time = Math.floor(Date.now() / 1000);

    const event = {
        object: {
            id: 'sdrn:e24:content:partnerstudio:element:partnerstudio:stats',
            type: 'Article',
            page: null,
            tags,
        },
        partnerstudio: {
            type: 'view',
            tracking_type: 'ad',
            frontId: 47,
            time,
            placement,
            link,
            imageUrl,
            device,
            variant,
            revision,
            drfrontId,
            clientName,
            creativeId,
            algorithm,
            editionId,
            itemId,
            url,
            xandrAdLoaded: false,
            title: getPartnerstudioTitle(item),
            subTitle: getPartnerstudioSubtitle(item),
        },
        provider: {
            id: 'e24',
            eventIdentifier: 'e24',
            productTag: 'partnerstudio',
        },
    };

    const pulse = await getPulse();
    pulse.trackElementView(event);

    const consentStatus = await hasConsentFor('CMP:advertising');
    if (consentStatus) {
        sendThirdpartyTracking(item);
    }
};
