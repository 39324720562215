import type { EventInputObject, EventInputTarget } from '@e24/mbbs';
import { getDeviceTypeFromRoot } from '@innhold/core/device/client';
import { DATA_VERSION } from '@innhold/core/front/constants';
import {
    DATA_ACCESS_LEVEL,
    DATA_ACCESS_LEVEL_NAME,
    DATA_BUNDLE,
    DATA_CONTENT_ID,
    DATA_ELEMENT_SIZE,
    DATA_EXPERIMENT_TEST_ID,
    DATA_EXPERIMENT_VARIANT_ID,
    DATA_IMAGE,
    DATA_METRICS,
    DATA_POSITION,
    DATA_SLUG,
    DATA_SOURCE,
    DATA_TITLE,
} from '@innhold/core/tracking/constants';
import { getPulse } from '@innhold/core/tracking/pulse';

const getMetrics = (element: Element, { key }: { key: string }) => {
    const source = 'Curate';
    const metrics = element.getAttribute(DATA_METRICS);
    const [r = 0, i = 0, p = 0] = metrics ? metrics.split(',').map(Number) : [];

    return [
        { key, name: 'removeRead', value: r, source },
        { key, name: 'removeImpressed', value: i, source },
        { key, name: 'personalizedResults', value: p, source },
    ];
};

const getLayoutElementSize = (element: Element, { isMobile = false } = {}) => {
    const elementSize = element.getAttribute(DATA_ELEMENT_SIZE);
    if (elementSize === 'stripe') {
        return isMobile ? 'small' : 'medium';
    }
    return elementSize;
};

const getObjectLayout = (element: Element, options: { isMobile?: boolean }) => {
    const elementSize = getLayoutElementSize(element, options);
    const imageUrl = element.getAttribute(DATA_IMAGE);

    return { imageUrl, elementSize };
};

const getPlacementPosition = (element: Element) => {
    const position = element.getAttribute(DATA_POSITION);
    return position ? position.split(',').map(Number) : [];
};

const getObjectPlacement = (
    element: Element,
    { context: curateContext }: { context: string }
) => {
    const [primaryPosition, secondaryPosition] = getPlacementPosition(element);
    const source = element.getAttribute(DATA_SOURCE);
    const baseOjectPlacement = {
        curateContext,
        primaryPosition,
        secondaryPosition,
        source,
    };

    const isPartOfBundle = element.hasAttribute(DATA_BUNDLE);
    if (isPartOfBundle) {
        const bundle = element.getAttribute(DATA_BUNDLE).split(',');
        return {
            ...baseOjectPlacement,
            bundleId: bundle[0],
            bundleComposition: bundle,
        };
    }

    return baseOjectPlacement;
};

const getBaseEventInput = (
    element: Element,
    options: {
        context: string;
        key: string;
        isMobile?: boolean;
    }
) => {
    const id = element.getAttribute(DATA_CONTENT_ID);
    const name = element.getAttribute(DATA_TITLE);
    const url = element.getAttribute(DATA_SLUG);
    const accessLevel = element.getAttribute(DATA_ACCESS_LEVEL);
    const accessLevelName = element.getAttribute(DATA_ACCESS_LEVEL_NAME);

    return {
        metrics: getMetrics(element, options),
        object: {
            id,
            name,
            type: 'Teaser',
            layout: getObjectLayout(element, options),
            placement: getObjectPlacement(element, options),
        } as EventInputObject,
        target: {
            id,
            name,
            type: 'Article',
            article_access_level: accessLevel,
            accessLevel: accessLevel,
            accessLevelName: accessLevelName,
            category: 'News',
            url,
        } as EventInputTarget,
    };
};

const isExperiment = (element: Element) => {
    return [DATA_EXPERIMENT_VARIANT_ID, DATA_EXPERIMENT_TEST_ID].every(
        (experimentAttribute) => element.hasAttribute(experimentAttribute)
    );
};

const getExperimentsData = (element: Element) => {
    const id = element.getAttribute(DATA_CONTENT_ID);
    return [
        {
            '@id': `sdrn:curate:experiment:teasers-${id}`,
            variant: element.getAttribute(DATA_EXPERIMENT_VARIANT_ID),
            name: `Teaser experiment for article ${id}`,
            custom: {
                testId: element.getAttribute(DATA_EXPERIMENT_TEST_ID),
            },
            platform: 'curate',
        },
    ];
};

export default async () => {
    const pulse = await getPulse();

    const version = document.querySelector('main').getAttribute(DATA_VERSION);
    const [context = 'frontpage', key = ''] = version ? version.split(':') : [];
    const options = { context, key, isMobile: getDeviceTypeFromRoot() === 'mobile' };

    const intersectionObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const input = getBaseEventInput(entry.target, options);

                    if (isExperiment(entry.target)) {
                        input['experiments'] = getExperimentsData(entry.target);
                    }

                    pulse.trackElementView(input);
                    intersectionObserver.unobserve(entry.target);
                }
            });
        },
        {
            rootMargin: '0px',
            threshold: 0.5,
        }
    );

    const setupTeaserTracking = (teaser: Element) => {
        teaser.addEventListener('click', () => {
            const input = getBaseEventInput(teaser, options);

            if (isExperiment(teaser)) {
                input['experiments'] = getExperimentsData(teaser);
            }

            pulse.trackElementClick(input);
        });
        intersectionObserver.observe(teaser);
    };

    const contentMutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node: Element) => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const isTeaserElement = node.matches(`a[${DATA_CONTENT_ID}]`);

                    if (isTeaserElement) {
                        setupTeaserTracking(node);
                    }
                }
            });
        });
    });

    const container = document.querySelector('.grid-container');
    const staticTeasers = document.querySelectorAll(`a[${DATA_CONTENT_ID}]`);

    staticTeasers.forEach(setupTeaserTracking);
    contentMutationObserver.observe(container, { subtree: true, childList: true });
};
