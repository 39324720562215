import type { Placement as CorePlacement } from '@sch-inventory/advertory/types/core/interface/config';
import type { DeviceType } from '@innhold/core/device/types';
import { placements as desktopPlacements } from './desktop';
import { placements as mobilePlacements } from './mobile';
import { placements as tabletPlacements } from './tablet';

export type Placement = CorePlacement & {
    subtype: string;
    format?: string[];
    group?: string;
    lazyload?: boolean;
    safeframe?: boolean;
    videoEnabled?: boolean;
};

export const placements = {
    mobile: mobilePlacements,
    tablet: tabletPlacements,
    desktop: desktopPlacements,
} as { [K in DeviceType]: Placement[] };

export function getPlacementsForDevice(deviceType: DeviceType) {
    return placements[deviceType] ?? [];
}
