import { getAccount } from '../account';
import { getClientTypeFromRoot, getDeviceTypeFromRoot } from '../device/client';
import { isAppClient } from '../device/helpers';
import { getPulse } from '../tracking/pulse';
import { getFinnBlinkAdsToReplace, getFinnBlinkSizes } from './finn';
import { areAdsBlocked } from './fluepapir';
import { placements } from './placements';

const prepareAdvertoryConfig = async () => {
    const account = await getAccount();
    const pulse = await getPulse();

    const deviceType = getDeviceTypeFromRoot();
    const isApp = isAppClient(getClientTypeFromRoot());
    const isTcfEnabled = Boolean(window._tcf_);
    const tracker = pulse.getSdk();
    const user = await account.getUser();
    const environmentId = await pulse.getSdk().getEnvironmentId();

    return {
        publisher: 'e24',
        country: 'no' as const,
        pageType: 'front' as const,
        deviceType,
        isMobileApp: isApp,
        astUrl: 'https://acdn.adnxs.com/ast/ast.js',
        pageOptions: {
            member: 9700,
            keywords: {
                'no-sno-publishergroup': 'schibsted',
                'aa-sch-inventory_type': 'editorial',
                'aa-sch-new-wallpaper-implementation': 'true',
            },
            user: {},
        },
        placements,
        appearanceConfig: {
            defaultThreshold: {
                desktop: 200,
                tablet: 200,
                mobile: 200,
            },
        },
        glimr: {
            enabled: true,
            clientId: 'H3IYO2A4LD43YPFZIJLN',
        },
        hashKeys: {
            userHashKey: 'SCHNO-UserHash',
            envHashKey: 'SCHNO-EnvHash',
        },
        tracker,
        prebid: {
            enabled: true,
            url: 'https://schibsted-cdn.relevant-digital.com/static/tags/6401c949746c8c3e086439b2.js',
            id: '6401c9561cc2e5f1b56439b3',
            externalUidInAuctionEnabled: true,
        },
        wallpaper: {
            enabled: true,
            options: {
                appMainContainerSelector: '#app',
                wallpaperAnchorSelector: '.advertory-e24-wallpaper-hero',
                annonseTextLineHeight: 15,
            },
        },
        tcf: {
            enabled: isTcfEnabled,
        },
        finnBlink: {
            enabled: true,
            userId: user ? `${user.userId}` : undefined,
            uuid: user ? `${user.uuid}` : undefined,
            channel: 'e24',
            replacementConfig: {
                isAdBlockEnabled: await areAdsBlocked(),
                adsToReplace: getFinnBlinkAdsToReplace(),
                sizes: getFinnBlinkSizes(deviceType),
            },
        },
        fullscreenScroll: {
            fadingHeaderEnabled: true,
            headerSelectors: ['.sticky-header'],
            headerZIndex: 10000,
            overlayStyles: {
                backgroundColor: 'var(--color-background-primary)',
                foregroundColor: 'var(--color-background-primary)',
                articlesContainerWidth: 1010,
                margin: '0 0 0 calc(-50vw + 50%);',
            },
        },
        queryConfig: {
            queryByDataDevice: false,
            lazyLoadingV3: {
                enabled: true,
                rootMargin: '200% 0px',
            },
            placementBatches: {
                desktop: {
                    wallpaperGroup: [
                        'skyscraperright_1',
                        'topboard',
                        'wallpaper',
                        'skyscraperleft_1',
                    ],
                },
            },
        },
        privacyBroker: {
            enabled: true,
            identifiedEndpoint: import.meta.env.PUBLIC_PRIVACY_BROKER_IDENTIFIED_ENDPOINT,
            anonymousEndpoint: import.meta.env.PUBLIC_PRIVACY_BROKER_ANONYMOUS_ENDPOINT,
            sdrnIdentified: 'sdrn:spid.no:user',
            sdrnAnonymous: 'sdrn:schibsted:environment',
            trackerEnvironmentId: environmentId,
            storageKeyPrefix: 'lbmp-permissions',
            userId: user?.userId,
            userSig: user?.sig,
            getIdentityObject: async () => {
                return Promise.resolve(window.Identity);
            },
        },
    };
};

export { prepareAdvertoryConfig };
